@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  color: #061d3a;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: revert;
}

.logo-big {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
}

.logo-small {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
}

.bg-plants {
  background-image: url(/public/images/background-plants.jpg);
  background-size: cover;
}

.font-poppins {
  font-family: 'Poppins', sans-serif;
}

.font-inter {
  font-family: 'Inter', sans-serif;
}

.font-montserrat {
  font-family: 'Montserrat', sans-serif;
}

.h-custom {
  background-color: transparent;
}

@media (max-width: 640px) {
  .h-custom {
    height: 250px;
  }
}
